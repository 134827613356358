<template>
    <div>
        <el-form>
            <el-form-item label="当前广告系列">
                <span>{{campaignName}}<span style="color:#F56C6C">({{campaignId}})</span></span>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                campaignId:this.$route.query.campaignId,
                campaignName:this.$route.query.campaignName,
            }
        },
        methods: {
            
        },
        watch:{
            campaignInfo:{
                handler(){
                    // console.log(this.campaignInfo);
                },
                deep:true
            }
        }
    }
</script>
<style lang="scss">
</style>