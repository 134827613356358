<template>
    <div>
        <draw-gauge :users="usersCount"></draw-gauge>
        <div class="hideFresh" @click="close" v-if="edit">收起<i class="el-icon-caret-right"></i></div>
        <div class="savePeople">
            <div class="peopleItem"> <span>地区：</span><div>{{statusData?(statusData.sentenceLines?statusData.sentenceLines.geo_locations:''):''}}</div></div>
            <div class="peopleItem"><span>语言：</span><div>{{statusData?(statusData.sentenceLines?statusData.sentenceLines.locales:''):''}}</div></div>
            <div class="peopleItem"><span>兴趣：</span><div>{{statusData?(statusData.sentenceLines?statusData.sentenceLines.flexible_spec:''):''}}</div></div>
            <div class="peopleItem"><span>性别：</span><div>{{statusData?(statusData.sentenceLines?statusData.sentenceLines.genders:''):''}}</div></div>
            <div class="peopleItem"><span>年龄组：</span><div>{{statusData?(statusData.sentenceLines?statusData.sentenceLines.age_range:''):''}}</div></div>
            <div class="btn" v-if="!edit">
                <el-button size="mini" @click="savePeopleShow = true">保存受众</el-button>
            </div>
        </div>
        <!-- 保存设置 -->
        <el-dialog title="保存受众" :visible.sync="savePeopleShow" width="600px">
            <div>
                <el-form :model="ruleForm" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="名称：" prop="name">
                        <el-input v-model="ruleForm.name" style="width:200px"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="savePeopleShow = false">取 消</el-button>
                <el-button type="primary" @click="saveBtn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {saveSavedContent} from '@/api/creatAd.js'
import DrawGauge from "./targeting/DrawGauge";
    export default {
        components: {DrawGauge},
        props:['statusData','content','accountId','edit'],
        // props:{
        //     statusData:{
        //         type:Object,
        //         default:()=>{}
        //     },
        //     content:{
        //         type:String,
        //         default:''
        //     },
        //     accountId:{
        //         type:Number,
        //         default:0
        //     },
        // },
        data() {
            return {
                savePeopleShow:false,
                ruleForm:{},
            }
        },
        mounted() {
            // console.log(this.count);
        },
        computed: {
            usersCount() {
                return this.statusData?(this.statusData.estimate_mau || this.statusData.estimate_dau || 0):0;
            },
        },
        methods: {
            close(){
                this.$emit('close','freshCon')
            },
            // 保存受众
            saveBtn(){
                this.ruleForm.content = this.content;
                this.ruleForm.accountId = this.accountId;
                this.ruleForm.type = "TARGETING";
                if(!this.ruleForm.name){
                    this.$message({
                        type:'warning',
                        message:'请输入受众名称'
                    })
                }else{
                    saveSavedContent(this.ruleForm).then(res=>{
                        if(res.code == 0){
                            this.$message({
                                type:'success',
                                message:'保存成功'
                            })
                            this.savePeopleShow = false;
                        }
                        // console.log(res);
                    })
                }
            }
        },
    }
</script>
<style lang="scss">
    .hideFresh{
        position: absolute;
        top: 20px;
        right: 10px;
        padding: 5px;
        border: 1px solid rgb(200, 224, 255);
        border-radius: 2px;
        font-size: 12px;
        color: #666;
    }
    .canvas{
        text-align: center;
        padding: 20px;
        border:1px solid rgb(200, 224, 255);
        padding-bottom: 0;
        p{
            font-size: 16px;
        }
    }
    .savePeople{
        padding: 20px;
        border:1px solid rgb(200, 224, 255);
        border-top: 0;
        font-size: 12px;
        .peopleItem{
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            span{
                margin-right: 10px;
                color: #666;
                flex: 48px 0 0;
            }
        }
        .btn{
            text-align: center;
        }
    }
</style>