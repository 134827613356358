<template>
    <div class="">
        <el-drawer title="管理受众列表"  :visible="audienceListShow" direction="rtl" @close="close" :append-to-body="true">
            <div class="audienceList">
                <div class="list">
                <ul>
                    <li v-for="item in myAudience" :key="item.id">
                        <p class="title">
                            <span class="name">{{item.name}}</span>
                            <el-button type="text" size="mini" @click="deleMyAudience(item.id)">删除</el-button>
                        </p>
                        <!-- <div class="section">
                            <p><span class="itemName">地区：</span><span>美国</span></p>
                            <p><span class="itemName">语言：</span><span>英语</span></p>
                            <p><span class="itemName">兴趣：</span><span>包含副学士学位,并且包含在读研究生,18-26 岁成年子女的父母,</span></p>
                            <p><span class="itemName">性别：</span><span>全部</span></p>
                            <p><span class="itemName">年龄组：</span><span>18-65</span></p>
                        </div>
                        <el-button size="mini" class="open" @click="open(0)">展开</el-button> -->
                    </li>
                </ul>
            </div>
            </div>
            
        </el-drawer>
    </div>
</template>
<script>
    import {savedContentsDel} from '@/api/creatAd.js'
    export default {
        props:['audienceListShow','myAudience'],
        data() {
            return {
                list:[]
            }
        },
        methods: {
            close(){
                this.$emit('close','audienceListShow')
            },
            getList(){
                
            },
            open(){
                
            },
            deleMyAudience(id){
                let formData = new FormData();
                formData.append('id',id)
                formData.append('savedType','TARGETING')
                savedContentsDel(formData).then(res=>{
                    if(res.code == 0){
                        this.$message({
                            type:'success',
                            message:'删除成功'
                        })
                        this.$emit('getAudienceContent')
                    }
                })
            }
        },
        mounted() {
            // this.getList()
        },
    }
</script>
<style lang="scss">
.audienceList{
    .list{
        padding: 30px;
        border-top: 1px solid #ddd;
        padding-top: 0;
        ul,li{
            list-style: none;
        }
        li{
            border-bottom: 1px solid #ddd;
            padding: 10px 0;
            font-size: 12px;
            color: #333;
            position: relative;
            overflow: hidden;
            .open{
                position: absolute;
                bottom: 20px;
                right: 50px;
            }
            .title{
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                .name{
                    flex: 1;
                }
                .el-button{
                    flex: 50px 0 0;
                }
            }
            .section{
                p{
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    line-height: 24px;
                    .itemName{
                        // margin-right: 10px;
                        color: #666;
                        flex: 50px 0 0;
                    }
                    span{
                        flex: 1; 
                    }
                }
            }
        }
    }
    .el-drawer.rtl{
        outline: none;
        *{
            outline: none;
        }
    }
}
</style>